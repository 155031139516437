<template>
	<div class="content" @keydown.esc="lightbox = false" tabindex="0">
		<!-- <div v-if="!this.isMobile" id="magnifierSwitch">magnification
			<button v-if="magnification" @click="magnification = false" @mouseenter="hoverCursor" @mouseleave="normalCursor" style="background-color:#00aa00"><b> On</b></button>
			<button v-if="!magnification" @click="magnification = true" @mouseenter="hoverCursor" @mouseleave="normalCursor" style="background-color:#bb0000"><b> Off</b></button>
		</div> -->
		<ul id="artworks">
				<li class="painting" v-bind:key="painting.id" v-for="painting in allPaintings" @mouseenter="$parent.selectedWork = painting">
					<div class="imgContainer">
						<img v-bind:id="painting.title" v-bind:src="painting.src" v-bind:alt="painting.title" @mouseenter="hovering = true; magnifyCursor()" @mouseleave="hovering = false; normalCursor()" @click="lightBox(painting)">
					</div>
					<h5 class="WorkTitle" v-if="!spanish">{{painting.title}}</h5>
					<h5 class="WorkTitle" v-if="spanish">{{painting.titleES}}</h5>
					<p class="workSummary" v-if="!spanish">{{painting.summary}}</p>
					<p  class="workSummary" v-if="spanish">{{painting.summaryES}}</p>
					<p class="workPrice" v-if="!spanish">Price: £ {{painting.price}}</p>
					<p class="workPrice" v-if="spanish">Precio: £ {{painting.price}}</p>
				</li>

		</ul>
		<!-- <img class="magnified" v-if="!isMobile && magnification" v-show="hovering" ref="magnified" v-bind:src="selectedWork.src" v-bind:alt="selectedWork.title"> -->


		<transition name="fade">	
		<div id="lightboxWrapper" ref="lightboxWrapper" v-if="lightbox" @click="hideLighbox" >	
			<div id="prevButton" v-if="!isMobile" @click="previousPainting" @mouseenter="hoverCursor" @mouseleave="normalCursor">
				<span>
					<svg  ref="arrow" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 30" width="40px" height="25px" style="enable-background:new 0 0 50 30; fill: white" xml:space="preserve">
						<g>
							<path d="M3,15c0-0.7,0.6-1.3,1.3-1.3h41.6c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3H4.3C3.6,16.3,3,15.7,3,15z"/>
							<path d="M3.2,15.8c-0.4-0.6-0.3-1.4,0.3-1.9L22.2,0.8c0.6-0.4,1.4-0.3,1.9,0.3s0.3,1.4-0.3,1.9L5.1,16.1c-0.2,0.2-0.5,0.2-0.8,0.2C3.9,16.3,3.5,16.1,3.2,15.8z"/>
							<path d="M22.3,29.2L3.5,16.1c-0.6-0.4-0.7-1.3-0.3-1.9c0.4-0.6,1.3-0.7,1.9-0.3l18.7,13.1c0.6,0.4,0.7,1.3,0.3,1.9c-0.2,0.4-0.7,0.5-1.1,0.5C22.8,29.5,22.5,29.4,22.3,29.2z"/>
</g>
					</svg>
				</span>
			</div>

				<div id="paintingWrapper" ref="paintingWrapper" v-touch:swipe.left="nextPainting" v-touch:swipe.right="previousPainting">
					<img v-bind:src="LBoxPainting.src" v-bind:alt="LBoxPainting.title">
					<h5 v-if="!isMobile">{{LBoxPainting.title}}</h5>
					<p v-if="!isMobile">{{LBoxPainting.summary}}</p>
				</div>

			<div id="nextButton" v-if="!isMobile" @click="nextPainting" @mouseenter="hoverCursor" @mouseleave="normalCursor"> 
				<span>
					<svg  ref="arrow" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 30" width="40px" height="25px" style="enable-background:new 0 0 50 30; fill: white" xml:space="preserve">
						<g>
	<path d="M45.9,16.3H4.3C3.6,16.3,3,15.7,3,15c0-0.7,0.6-1.3,1.3-1.3h41.6c0.7,0,1.3,0.6,1.3,1.3C47.2,15.7,46.6,16.3,45.9,16.3z"/>
	<path d="M45.9,16.3c-0.2,0-0.5-0.1-0.8-0.2L26.4,2.9c-0.6-0.4-0.7-1.3-0.3-1.9s1.3-0.7,1.9-0.3l18.7,13.1c0.6,0.4,0.7,1.3,0.3,1.9C46.7,16.1,46.3,16.3,45.9,16.3z"/>
	<path d="M27.2,29.5c-0.4,0-0.8-0.2-1.1-0.5c-0.4-0.6-0.3-1.4,0.3-1.9l18.7-13.1c0.6-0.4,1.4-0.3,1.9,0.3c0.4,0.6,0.3,1.4-0.3,1.9L27.9,29.2C27.7,29.4,27.4,29.5,27.2,29.5z"/>
</g>
					</svg>
				</span>
			</div>
		</div>
		</transition>
	</div>
</template>

<script>
import {gsap} from 'gsap';
export default {
	data(){
		return{
			hovering: false,
			magnification: true,
			lightbox: false,
			allPaintings: this.$root.allWorks.RoomEA.paintings.concat(this.$root.allWorks.Room1.paintings, this.$root.allWorks.Room2.paintings, this.$root.allWorks.Basement.paintings),
			LBoxPainting: "",
			LBoxNextPainting: "",
			LBoxPrevPainting: "",

		}
	},
	props:[
		'selectedWork',
		'isMobile',
		'hoverCursor',
		'normalCursor',
		'spanish'
	],

	mounted(){
	},
	
	methods: {
		magnifier(){
			var paintings = Object.values(document.querySelectorAll("#artworks li img"));
			for(var i=0; i<paintings.length; i++){
				paintings[i].addEventListener("mousemove", this.moveImg)
			}
		},
		magnifyCursor(){
			if(!this.isMobile){
				let zoom = 5
				gsap.to(this.$parent.$refs.circle, {duration:0.6, scale: zoom , ease: "power3.out"})
				gsap.to(this.$parent.$refs.crossCursor, {duration:0.6, opacity: 0, ease: "power3.out"})
				gsap.to(this.$parent.$refs.magnify, {duration:0.6,  opacity: 1, ease: "power3.out"})
			}
		},
		moveImg(e) {
			if(this.magnification && !this.isMobile){
				let magnified = this.$refs.magnified;
				if(e.clientY < window.innerHeight-magnified.offsetHeight && e.clientX < window.innerWidth-magnified.offsetWidth){
					gsap.to(magnified, {duration:0.6, x : e.clientX , y : e.clientY});
				}
				if(e.clientY >= window.innerHeight-magnified.offsetHeight && e.clientX < window.innerWidth-magnified.offsetWidth){
					gsap.to(magnified, {duration:0.6, x : e.clientX, y:window.innerHeight-magnified.offsetHeight});
				}
				if(e.clientY < window.innerHeight-magnified.offsetHeight && e.clientX >= window.innerWidth-magnified.offsetWidth){
					gsap.to(magnified, {duration:0.6, x: window.innerWidth-magnified.offsetWidth,  y : e.clientY});
				}
				if(e.clientY >= window.innerHeight-magnified.offsetHeight && e.clientX >= window.innerWidth-magnified.offsetWidth){
					gsap.to(magnified, {duration:0.6, x: window.innerWidth-magnified.offsetWidth,  y:window.innerHeight-magnified.offsetHeight});
				}
			}
		},
		lightBox(painting) {
			this.lightbox = true;
			this.LBoxPainting = painting
			let paintingIndex = this.allPaintings.indexOf(painting)
			this.LBoxNextPainting = this.allPaintings[paintingIndex+1]
			this.LBoxPrevPainting = this.allPaintings[paintingIndex-1]
		},
		nextPainting(){
			let paintingIndex = this.allPaintings.indexOf(this.LBoxPainting)
			if(paintingIndex < this.allPaintings.length-1){
				gsap.to(this.$refs.paintingWrapper, {duration: .3, opacity: 0, onComplete: () => {
					this.LBoxPainting = this.LBoxNextPainting;
					gsap.to(this.$refs.paintingWrapper, {duration: .3, opacity: 1})
				}})
				this.LBoxNextPainting = this.allPaintings[paintingIndex+1]
				this.LBoxPrevPainting = this.allPaintings[paintingIndex-1]
			}
		},
		previousPainting(){
			let paintingIndex = this.allPaintings.indexOf(this.LBoxPainting)
			if(paintingIndex > 0){
				gsap.to(this.$refs.paintingWrapper, {duration: .3, opacity: 0, onComplete: () => {
					this.LBoxPainting = this.LBoxPrevPainting;
					gsap.to(this.$refs.paintingWrapper, {duration: .3, opacity: 1})
				}})
				this.LBoxNextPainting = this.allPaintings[paintingIndex+1]
				this.LBoxPrevPainting = this.allPaintings[paintingIndex-1]
			}
		},
		hideLighbox(event){
			if (event.target == document.querySelector("#lightboxWrapper")){
				this.lightbox = false
			}
		}
	},


}
</script>

<style lang="scss" scoped>
  @mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }  
	@mixin for-tablet-portait-only {
    @media (min-width: 600px) and (max-width: 899px) { @content; }
  }
#magnifierSwitch{
	position:absolute;
	top: 100px;
	right: 40px;
	font-size: 0.7rem;
	button{
		font-size: 0.7rem;
		padding:0.5rem 1rem;
		margin: 0 0 0 0.5rem;
		border: none;
		border-radius: 50px;
		cursor: none;
		color: white;
	}
	button:hover{
		color: #dddddd;
	}
}
#artworks{
	width: 96vw;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding: 100px 2vw 0 2vw;
	// overflow-x: hidden;
}
.painting{
	@include for-phone-only(){
		width: 45%;
		margin: 3vh 1%;
		padding: 1% 1%;
	}
	@include for-tablet-portait-only(){
		width: 45%;
		margin: 3vh 1%;
		padding: 1% 1%;
	}
	width: 22%;
	margin: 3vh 2%;
	border: solid 1px;
	padding: 1% 2%;
	list-style: none;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	.imgContainer{
		width: 100%;
		margin: auto 0;
		align-self: center;
		flex: 0 0 auto;
		object-fit: contain;
		img{
			width: 100%;
			image-rendering: -webkit-optimize-contrast;
			will-change: transform;
		}
	}
	.workSummary{
		@include for-phone-only(){
		height: 9rem;
		}
		line-height: 1.5rem;
		height: 6rem;
	}
	h5{
		margin: 1rem 0 0 0;
		padding: 0 0 0.5rem 0;
		font-weight: 400;
		border-bottom: solid 1px;
	}
	p{
		margin: 1rem 0 0 0;
		white-space: pre-wrap;
		color: #777777;
		font-size: .9rem;
		flex-shrink: 1;	
	}
}
.magnified{
	position: fixed;
	top: 0;
	max-width: 50vw;
	max-height: 75vh;
	pointer-events: none;
	image-rendering: -webkit-optimize-contrast;
}

#lightboxWrapper{
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: calc(100vh - 70px);
	padding: 70px 0 0 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #55555555;
	backdrop-filter: blur(3px) brightness(50%);
	-webkit-backdrop-filter: blur(3px) brightness(50%);
	#paintingWrapper{
		@include for-phone-only(){
		height: initial;
		max-width: 100%;
		max-height: 90%;
		padding: 0;
		margin: 0;
		background: none;
		}
		max-width: 90%;
		height: 90%;
		padding: 10px;
		margin: 0 40px;
		background-color: #ffffff;
		display: flex;
		flex-direction: column;
		img{
			max-width: 100%;
			max-height: 85%;
			flex-shrink: 1;
		}
		h5{
			margin: 1rem 0 0 0;
		}
		p{
			margin: 1rem 0 1rem 0;
			white-space: pre-wrap;
			color: #777777;
		}
	}
	#nextButton{
		// position: absolute;
		right: 20px;
		height: calc(100% - 70px);
		display: flex;
		align-items: center;
		color: #fff;
		span{
			margin: auto;
		}
	}
	#prevButton{
		// position: absolute;
		left: 20px;
		height: calc(100% - 70px);
		display: flex;
		align-items: center;
		color: #fff;
		span{
			margin: auto;
		}
	}
}


.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

</style>