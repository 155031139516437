<template>
	<div class="content">
		<div class="scrollTarget">
			<div class="work" ref="work">
				<div class=" section introText">
					<h1 v-if="!spanish">Painterly expressions</h1>
					<h1 v-if="spanish">Expresiones pictóricas</h1>
					<p v-if="!spanish">In this room, we present you a selection of 20 small paintings on paper, created in the last two years. These artworks highlight the artist's exploration of painting as a subject in itself, creating aesthetic expressions centred on a theme of cellular and organic forms.</p>
					<p v-if="spanish">En esta sala, presentamos una selección de 20 pinturas formato pequeño sobre papel, creados en los últimos dos años. En estas obras destacan la exploración del artista de la pintura como un tema en sí mismo, creando expresiones estéticas centradas en un tema de formas celulares y orgánicas.</p>
					<p class="arrow">
						<span>
							<svg  ref="arrow" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 50" width="75px" height="30px" style="enable-background:new 0 0 100 50;" xml:space="preserve">
								<g>
									<path d="M93.9,27.2H6.1c-1.2,0-2.2-1-2.2-2.2s1-2.2,2.2-2.2h87.7c1.2,0,2.2,1,2.2,2.2S95.1,27.2,93.9,27.2z"/>
									<path d="M93.9,27.2c-0.4,0-0.9-0.1-1.3-0.4L61.4,4.9c-1-0.7-1.2-2.1-0.5-3.1c0.7-1,2.1-1.2,3.1-0.5l31.2,21.9c1,0.7,1.2,2.1,0.5,3.1C95.2,26.9,94.5,27.2,93.9,27.2z"/>
									<path d="M62.7,49.1c-0.7,0-1.4-0.3-1.8-0.9c-0.7-1-0.5-2.4,0.5-3.1l31.2-21.9c1-0.7,2.4-0.5,3.1,0.5c0.7,1,0.5,2.4-0.5,3.1L63.9,48.7C63.5,49,63.1,49.1,62.7,49.1z"/>
								</g>
							</svg>
						</span>
					</p>
				</div>
				<img v-for="painting in $root.allWorks.Room2.paintings" v-bind:key="painting.id" v-bind:src="painting.src" v-bind:alt="painting.title">
			</div>
			<div class="background"></div>
		</div>
	</div>
</template>

<script>
import {gsap} from 'gsap';
export default {
	data() {
		return{
			id: this.$root.allWorks.Room2.id,
			showDetails: false,
		}
	},
	props:[
        'spanish'
	],
	
	methods: {
		scrollRight(){
			const arrow = this.$refs.arrow;
			var tl = gsap.timeline();
			tl.set(arrow, {x: -100})
			tl.to(arrow, {x: 100, duration: 1.5, repeat:3})
			tl.fromTo(arrow, {x:-100}, {x:0, duration: 1.5})
		}
		
	},
	mounted(){
		this.scrollRight();
	}

}
</script>

<style lang="scss" scoped>


  @mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }

.background{
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    z-index: -1;
    background-size: 80px 80px;
    background-image: radial-gradient(circle, #d5d5d5 1px, #ffffff 4px);
    // background-image: linear-gradient(to right, #cccccc 2p	x, transparent 1px), linear-gradient(to bottom, #cccccc 1px, transparent 1px);
}



</style>