
import { firebase } from '@firebase/app'
require('firebase/firestore')
require('firebase/app')
require('firebase/auth')
require('firebase/storage')

var firebaseConfig = {
  apiKey: "AIzaSyDbMSs1FT3JNCw3D2dv-fdghu2p__BaS2c",
  authDomain: "pacorrosa-website.firebaseapp.com",
  databaseURL: "https://pacorrosa-website.firebaseio.com",
  projectId: "pacorrosa-website",
  storageBucket: "pacorrosa-website.appspot.com",
  messagingSenderId: "872885364579",
  appId: "1:872885364579:web:90d0c0ce0a5a89f8019556",
  measurementId: "G-LJ4LWSGX01"
};

var fb = firebase.initializeApp(firebaseConfig);

var db = firebase.firestore();
export {fb, db};

// Vue.prototype.$firebaseDatabase = firebase.default.firestore()