<template>
	<div class="details">
		<div class="close" @click="closeDetails" @mouseenter="hoverCursor" @mouseleave="normalCursor">
			<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" height="20px" width="20px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
				<g><path d="M2.3,50c-0.6,0-1.2-0.2-1.6-0.7c-0.9-0.9-0.9-2.3,0-3.2L46.1,0.7c0.9-0.9,2.3-0.9,3.2,0c0.9,0.9,0.9,2.3,0,3.2L3.9,49.3C3.4,49.8,2.9,50,2.3,50z"/></g>
				<g><path d="M47.7,50c-0.6,0-1.2-0.2-1.6-0.7L0.7,3.9c-0.9-0.9-0.9-2.3,0-3.2c0.9-0.9,2.3-0.9,3.2,0l45.5,45.5c0.9,0.9,0.9,2.3,0,3.2C48.9,49.8,48.3,50,47.7,50z"/></g>
			</svg>
		</div>
		<div class="detailsWrapper">
			<img ref="selectedWork" v-bind:src="selectedWork.src" v-bind:alt="selectedWork.title">
			<div class="workDetails">
				<div class="titleWrapper">
					<h3 class="title" v-if="!spanish">{{selectedWork.title}}</h3>
					<h3 class="title" v-if="spanish">{{selectedWork.titleES}}</h3>
				</div>
				<div class="workSummary">
					<p class="summary" v-if="!spanish">{{selectedWork.summary}}</p>
					<p class="summary" v-if="spanish">{{selectedWork.summaryES}}</p>
				</div>
				<div class="workDescription">
					<p class="description" v-if="!spanish">{{selectedWork.description}}</p>
					<p class="description" v-if="spanish">{{selectedWork.descriptionES}}</p>
				</div>
				<div class="priceWrapper">
					<div class="thePrice" v-if="selectedWork.available">
						<h5 v-if="!spanish" class="price">Price: {{"£" + selectedWork.price}}</h5>
						<h5 v-if="spanish" class="price">Precio: {{"£" + selectedWork.price}}</h5>
					</div>
					<div class="sold" v-if="!selectedWork.available">
						<h5 class="price">SOLD</h5>
					</div>
					<button class="enquiryBtn" @click="enquiry = true" @mouseenter="hoverCursor" @mouseleave="normalCursor">purchase enquiry</button>
					<!-- <addToCart :selectedWork="selectedWork"></addToCart> -->
				</div>
			</div>
		</div>
		<div class="closeUps" v-if="'detailImgs' in selectedWork">
			<img class="closeUp" v-for="detail in selectedWork.detailImgs" :key="detail.id" :src="detail" :alt="selectedWork.title">
		</div>
		<div id="enquiryWrapper" v-if="enquiry" ref="enquiryWrapper" @click="closeEnquiry">
			<div class="modal">
				<purchaseEnquiryform :selectedWork="selectedWork" :hoverCursor="hoverCursor" :normalCursor="normalCursor"></purchaseEnquiryform>
				<p class="formInstructions">Please use this form to send us an email. alternatively, you can click on one of the channels below to send a message via a messaging app.</p>
				<div class="alternatives">
					<div class="whatsapp" @mouseenter="hoverCursor" @mouseleave="normalCursor">
						<a href="https://wa.me/447582222073" target="_blank"><img src=".././assets/imgs/icons/WhatsApp.svg" alt="whatsapp"></a>
					</div>
					<div class="telegram" @mouseenter="hoverCursor" @mouseleave="normalCursor">
						<a href="https://t.me/pacorrosa" target="_blank"><img src=".././assets/imgs/icons/Telegram.svg" alt="telegram"></a>
					</div>
					<div class="messenger" @mouseenter="hoverCursor" @mouseleave="normalCursor">
						<a href="https://m.me/1437315543180390" target="_blank"><img src=".././assets/imgs/icons/Messenger.svg" alt="telegram"></a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import purchaseEnquiryform from './purchaseEnqiryForm.vue'
export default {
  components: { 
		purchaseEnquiryform,
	},
	data(){
		return{
			enquiry: false,
		}
	},
	props:[
		'spanish',
		'selectedWork',
		'hoverCursor',
		'normalCursor'
	],
	computed:{
		imgHeight: function(){
			return this.$refs.selectedWork.y
		}
	},
	methods:{
		closeEnquiry(e){
			if (e.target === this.$refs.enquiryWrapper){
				this.enquiry = false;
			}
		},
		closeDetails(){
			this.$emit("closeDetails")
		},
		buy(){
			this.$emit("clickedBuy")
		}
	}
}
</script>

<style lang='scss' scoped>

@mixin for-phone-only {
	@media (max-width: 599px) { @content; }
}

.details{
	@include for-phone-only(){
		flex-direction: column;
	}
	position: fixed;
	top: 0;
	left: 0;
	width: 96vw;
	height: calc(100vh - 60px);
	padding: 80px 2vw 30px 2vw;
	background-color: #f6f6f6;
	clip-path: inset(0 100% 0 0);
	z-index: 10;
	overflow-y: scroll;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
		//firefox
		scrollbar-width: none;
		::-webkit-scrollbar {
			display: none;
		}

	.closeUps{
		display: flex;
		flex-direction: column;
		flex: 0 0 auto;
		width: 100%;
		justify-content: center;
		flex-wrap: wrap;
		padding: 3rem 0 5rem 0;
		.closeUp{
			@include for-phone-only(){
			padding: 2rem 0 2rem 0;
			}
			align-self: center;
			max-width: 90%;
			max-height: 90vh;
			height: auto;
			padding: 3rem 0 3rem 0;
		}
	}
}

.detailsWrapper{
	@include for-phone-only(){
		flex-direction: column;
		padding: 2rem 0 0 0;
		height: initial;
	}
	width: 100%;
	height: calc(100vh - 80px);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 0 0 0;
	img{
		@include for-phone-only(){
			max-width: 100%;
		}
		max-width: 65%;
		max-height: 85vh;
		flex-shrink: 0;
    image-rendering: -webkit-optimize-contrast;
    will-change: transform;

	}
	.workDetails{
		@include for-phone-only(){
			padding: 2rem 0 0 0;
			max-width: 95%;
			max-height: initial;
			overflow: visible;
			height: initial;
		}
		::-webkit-scrollbar {
			display: none;
		}
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		max-width: 40%;
		max-height: 85vh;
		padding: 0 0 0 2rem;
		overflow: hidden;
	}
	.titleWrapper{
		flex: 1 0 auto;
		overflow: hidden;
		.title{
			@include for-phone-only(){
				padding: 0;
				height: auto;
			}
			margin: 0 0 2rem 0;
		}
	}
	.workSummary{
		flex: 1 0 auto;
		overflow: hidden;
		.summary{
			margin: 0 0 2rem 0;
			white-space: pre-wrap;
		}
	}
	.workDescription{
		@include for-phone-only(){
		mask-image: initial;
		height: auto;
		}
		flex: 0 1 auto;
		height: 60%;
		border-top: solid 2px;
		border-bottom: solid 2px;
		mask-image: linear-gradient( to bottom,  rgba(0,0,0,0) 0%, rgba(0,0,0,1) 1%, rgba(0,0,0,1) 95%,  rgba(0,0,0,0) 102%);
		overflow-y: scroll;
		//firefox
		scrollbar-width: none;
		.description{
			@include for-phone-only(){
				height: auto;
			}
			margin: 0;
			padding: 0;
			text-align: justify;
			white-space: pre-wrap;
		}
	}
	.priceWrapper{
		flex: 1 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		overflow: hidden;
		button{
			height: 50%;
		}
	}
	.price{
		margin: 2rem 0 0 0;
	}
}

.close{
	@include for-phone-only(){
	right: 10px;
	}
	position: fixed;
	top: 70px;
	right: 10px;
	z-index: 25;
	// cursor: pointer;
}
.enquiryBtn{
	padding: 10px;
	border-radius: 20px;
	background-color: black;
	color: white;
	font-weight: bold;
	border: none;
}
#enquiryWrapper{
	position: fixed;
	top:0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.4);
	z-index: 30;
	overflow-y: scroll;
		//firefox
	scrollbar-width: none;
	.modal{
		@include for-phone-only(){
			width: 75vw;
		}
		width: 50%;
		padding: 30px;
		margin: 100px auto;
		border-radius: 10px;
		background: white;
		box-shadow: 0 0 8px rgba(0,0,0,0.1);
		.formInstructions{
			font-size: 0.8rem;
			margin: 10px 0;
			// padding: 0 40% 0 0;
		}
	}
}
.alternatives{
	height: 50px;
	display: flex;
	.whatsapp, .telegram, .messenger{
		width: 50px;
		margin: 0 20px 0 0;
	}
}

</style>