<template>
	<div class="content">
		<div class="scrollTarget">
			<div class="work" ref="work">
				<div class=" section introText">
					<h1 v-if="!spanish">Room 1</h1>
					<h1 v-if="spanish">Sala 1</h1>
					<p v-if="!spanish"> Welcome to Room One of this virtual gallery at pacorrosa.com.<br> The exhibition at Room One brings to you a selection of nine artworks from 2019 and 2020. You can view artworks and read about them here by clicking on the artwork. For purchase enquiries, please get in touch with us using the purchase enquiry button in the painting's page or via the form in the info page.<br> The selection of artworks in this room is focused on the social change we are experiencing at the beginning of the 3rd decade of the 3rd Millennium.<br><span v-if="!isMobile"> scroll up/down to move right/left</span></p>
					<p v-if="spanish"> Bienvenidos a la inauguración de la Sala UNO de este edificio virtual, en pacorrosa.com<br> la exposición "Sala UNO" muestra 9 obras de arte seleccionadas de los últimos 2 años hasta que los constructores terminan la arquitectura.<br>La selección se basa en piezas de arte relacionadas con el cambio social que estamos viviendo al inicio de la 3ª década del 3º Milenio.</p>
					<p class="arrow">
						<span>
							<svg  ref="arrow" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 50" width="60px" height="25px" style="enable-background:new 0 0 100 50;" xml:space="preserve">
								<g>
									<path d="M93.9,27.2H6.1c-1.2,0-2.2-1-2.2-2.2s1-2.2,2.2-2.2h87.7c1.2,0,2.2,1,2.2,2.2S95.1,27.2,93.9,27.2z"/>
									<path d="M93.9,27.2c-0.4,0-0.9-0.1-1.3-0.4L61.4,4.9c-1-0.7-1.2-2.1-0.5-3.1c0.7-1,2.1-1.2,3.1-0.5l31.2,21.9c1,0.7,1.2,2.1,0.5,3.1C95.2,26.9,94.5,27.2,93.9,27.2z"/>
									<path d="M62.7,49.1c-0.7,0-1.4-0.3-1.8-0.9c-0.7-1-0.5-2.4,0.5-3.1l31.2-21.9c1-0.7,2.4-0.5,3.1,0.5c0.7,1,0.5,2.4-0.5,3.1L63.9,48.7C63.5,49,63.1,49.1,62.7,49.1z"/>
								</g>
							</svg>
						</span>
					</p>
				</div>
				<img v-for="painting in $root.allWorks.Room1.paintings" v-bind:key="painting.id" v-bind:src="painting.src" v-bind:alt="painting.title">
			</div>
			<div class="background"></div>
		</div>
	</div>
</template>

<script>
import {gsap} from 'gsap';
import {db} from '../firebase'
export default {
	data() {
		return{
			id: this.$root.allWorks.Room1.id,
			showDetails: false,
			Room1Paintings: []
		}
	},
	props:[
        'spanish',
				'isMobile'
	],
	
	methods: {
		scrollRight(){
			const arrow = this.$refs.arrow;
			var tl = gsap.timeline();
			tl.set(arrow, {x: -100})
			tl.to(arrow, {x: 100, duration: 1.5, repeat:3})
			tl.fromTo(arrow, {x:-100}, {x:0, duration: 1.5})
		},
		getPaintings(){
      db.collection("Room1").orderBy('id').get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          var painting = {id: doc.id, data: doc.data()}
          this.Room1Paintings.push(painting)
        });
				this.$emit('roomLoaded')
      });
    }
	},
	created(){
		this.getPaintings()
		// console.log(this.Room1Paintings)
	},
	mounted(){
		this.scrollRight();
	},

}
</script>

<style lang="scss" scoped>


  @mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }


.background{
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    z-index: -1;
    background-size: 80px 80px;
    background-image: radial-gradient(circle, #d2d2d2 1px, #ffffff 4px);
    // background-image: linear-gradient(to right, #cccccc 2px, transparent 1px), linear-gradient(to bottom, #cccccc 1px, transparent 1px);
}



</style>