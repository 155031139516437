<template>
  <div class="wrapper">
    <button class="addToCart" @click="addToCart()"> add to cart

    </button>
  </div>
</template>

<script>
export default {
  data(){
    return{
    }
  },
  props:[
    'selectedWork'
  ],
  mounted(){
    if (localStorage.getItem('cart')) {
      try {
        this.$root.cart = JSON.parse(localStorage.getItem('cart'));
      } catch(e) {
        localStorage.removeItem('cart');
      }
    }
  },
  methods: {
    addToCart(){
      this.$root.cart.push(this.selectedWork);
      this.saveCart();
      console.log(this.$root.cart)
    },
    saveCart(){
      const parsed =JSON.stringify(this.$root.cart)
      localStorage.setItem('cart', parsed)
    }
  }

}
</script>

<style>

</style>