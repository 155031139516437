<template>
  <div class="adminWrapper">
    

    <div class="adminContainer">
    <div class="sideBar">
      <h5>
        <router-link to="/admin"> admin</router-link>
      </h5>
      <ul>
        <li>
          <router-link to="/admin/adminPaintings">paintings</router-link>
        </li>
      </ul>
      <button @click="logout" id="logoutBtn">logout</button>
    </div>
    <div class="adminArea">
      <router-view></router-view>
    </div>
  </div>
  </div>
</template>

<script>
import {fb} from '../firebase'
export default {
  data(){
    return{
      loggedin: false,
    }
  },
  methods:{
    closeModal(e){
      if (e.target === this.$refs.login){
        this.loggedin = true;
      }
    },
    
    logout(){
      fb
      .auth()
      .signOut()
      .then(() => {
        alert('Successfully logged out');
        this.$router.push('/');
      })
      .catch(error => {
        alert(error.message);
        this.$router.push('/');
      });
    }
  }
}
</script>

<style lang="scss">
.adminContainer a{
  color: white;
}
.adminContainer{
  display: flex;
  padding: 60px 0 0 0;
}
.sideBar{
  position: sticky;
  padding: 0 10px 0 10px;
  top: 60px;
  width: 20%;
  height: 100vh;
  background-color: #333333;
  li{
    list-style: none;
    a{
      color: white;
    }
  }
  #logoutBtn{
    margin: 100px auto 0 auto;
    display: block;
    color: white;
    background-color: red;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
  }
}
.adminArea{
  // height: 100%;
  width: 100%;
}
.login{
	position: fixed;
	top:0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.4);
	z-index: 30;
  .modal{
		width: 50%;
		padding: 30px;
		margin: 100px auto;
		border-radius: 10px;
		background: white;
		box-shadow: 0 0 8px rgba(0,0,0,0.1);
		.formInstructions{
			font-size: 0.8rem;
			margin: 10px 0;
			// padding: 0 40% 0 0;
		}
	}
}
</style>