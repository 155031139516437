<template>
  <div class="about content">
    <router-link to="/ea" id="backButton" @mouseenter.native="hoverCursor" @mouseleave.native="normalCursor">
      <span>back to art
        <svg  ref="arrow" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 50" width="40px" height="10px" style="enable-background:new 0 0 100 50;" xml:space="preserve">
          <g>
            <path d="M93.9,27.2H6.1c-1.2,0-2.2-1-2.2-2.2s1-2.2,2.2-2.2h87.7c1.2,0,2.2,1,2.2,2.2S95.1,27.2,93.9,27.2z"/>
            <path d="M93.9,27.2c-0.4,0-0.9-0.1-1.3-0.4L61.4,4.9c-1-0.7-1.2-2.1-0.5-3.1c0.7-1,2.1-1.2,3.1-0.5l31.2,21.9c1,0.7,1.2,2.1,0.5,3.1C95.2,26.9,94.5,27.2,93.9,27.2z"/>
            <path d="M62.7,49.1c-0.7,0-1.4-0.3-1.8-0.9c-0.7-1-0.5-2.4,0.5-3.1l31.2-21.9c1-0.7,2.4-0.5,3.1,0.5c0.7,1,0.5,2.4-0.5,3.1L63.9,48.7C63.5,49,63.1,49.1,62.7,49.1z"/>
          </g>
        </svg>
      </span>
    </router-link>
    <div class="section bio">
      <img src="../assets/imgs/Portrait.jpg" alt="Pacorrosa portrait">
      <div id="bioTxt">
        <p v-if="!spanish"> Pacorrosa is a prolific Spanish artist based in London and Cordoba. He has over 25 years of experience and specialises in large scale artworks and projects.  During his career, Pacorrosa has developed different methods in creating and exhibiting art. He uses art as a means to acquiring new knowledge in several fields where creativity is essential. Although his primary medium is painting, he worked in several other media and formats, such as video art and landscape art, like his project Sketch for a Satellite. One of his main objectives is to extend the agency of art in the public discourse through creating artworks in public spaces.</p>
        <p v-if="spanish"> Pacorrosa es un prolífico artista español afincado en Londres y Córdoba, especializado en gigantescas obras de arte y proyectos. Con más de 25 años de experiencia. Durante su carrera, Pacorrosa ha desarrollado diferentes formas de hacer y exhibir arte. Su medio principal es la pintura, sin embargo, trabajó en varios otros medios y formatos, como el arte del paisaje como Sketch for a Satellite y el videoarte. Su objetivo es ampliar la radio de acción del Arte al público en general, realizando obras de arte en espacios públicos, por ejemplo, paisajismo. Utiliza el arte como herramienta para adquirir nuevos conocimientos en diferentes campos donde la creatividad es fundamental.</p>
        <div class="contact">
          <p v-if="!spanish">for purchase enquiries please get in touch using the email address below</p>
          <p v-if="spanish">Para consultas de compra, comuníquese con la dirección de correo electrónico a continuación.</p>
          <a @mouseenter="hoverCursor" @mouseleave="normalCursor" href="mailto:pacorrosa@gmail.com">pacorrosa@gmail.com</a>
          <p id="oldWebsite"> Please visit <a href="https://artglobaleducation.com/" @mouseenter="hoverCursor" @mouseleave="normalCursor" target="_blank"><u>artglobaleducation.com</u></a> to see more art</p>
        </div>
      </div>
    </div>
    <div id="morePhotos">
      <img src="../assets/imgs/Pacorrosa_infront_of_painting.jpg" alt="Pacorrosa portrait">
      <img src="../assets/imgs/Pacorrosa_carrying_painting.jpg" alt="Pacorrosa portrait">
    </div>
    <vueForm v-bind:hoverCursor="hoverCursor" v-bind:normalCursor="normalCursor"></vueForm>
    <div id="copywrites">
      <p>this website was designed and built by <a href="https://yamengfx.com/" @mouseenter="hoverCursor" @mouseleave="normalCursor" target="_blank"><u>Yamen Albadin</u></a></p>
    </div>
  </div>
</template>

<script>
import vueForm from '../components/vueForm'
export default {
  components: { vueForm },
  props:[
    'hoverCursor',
    'normalCursor',
    'spanish'
	],
}
</script>

<style lang="scss" scoped>
@import '../css/app.scss';
.about{
  display: flex;
  flex-direction: column;
}

.bio{
  @include for-phone-only(){
    // flex-direction: column;
    flex-wrap: wrap;
    padding: 100px 1rem;
    height: auto;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  padding: 10vh 10vw 10vh 10vw;
  img{
    @include for-phone-only(){
      max-width: 90%;
      // min-height: 0;
    }
    max-width: 50%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
  #bioTxt{
    padding: 2em;
    font-weight: 300;
    font-size: 1em;
    h1{
      margin: 0;
    }
  }
  .contact{
    a{
      display: inline-block;
    }
  }
}
#backButton{
  position: absolute;
  right: 15px;
  top: 70px;
}

#morePhotos{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include for-phone-only(){
    padding: 0 1rem;
  }
  padding: 0 10vw 0 10vw;
  img{
    max-width: 100%;
    padding: 0 0 5vh 0;
		align-self: center;
  }
}
#oldWebsite{
  a{
    display: inline-block;
  }
}
#copywrites{
  padding: 2rem;
  p{
    font-size: 0.7rem;
  }
  a{
    display: inline-block;
  }
}
</style>
