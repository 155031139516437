<template>
  <div id="form-wrapper">
    <form class="vue-form" @submit.prevent="submit">
      <fieldset>
        <div>
          <label class="label" for="name">Name</label>
          <p class="error-message" v-if="!validName && name.allowValidation">A name is required</p>
          <input type="text" name="name" id="name" required v-model="name.value" v-on:blur="name.allowValidation = true" v-on:focus="name.allowValidation = false">
        </div>
        <div>
          <label class="label" for="email">Email</label>
          <p class="error-message" v-if="!validEmail && email.allowValidation">Please enter a valid email address.</p>
          <input type="email" name="email" id="email" required :class="{ email , error: !email.valid }" v-model="email.value" v-on:blur="email.allowValidation = true" v-on:focus="email.allowValidation = false">
        </div>
        <div>
          <label class="label" for="email">Subject</label>
          <p id="subject"  class="subject">{{subject.value}}</p>
        </div>
        <div>
          <label class="label" for="textarea">Message</label>
          <p class="error-message" v-if="!validMessage && message.allowValidation">Message should be longer than 60 characters</p>
          <span class="counter">{{ message.value.length }} / {{ message.maxlength }}</span>
          <textarea class="message" name="textarea" id="textarea" required v-model="message.value" v-on:blur="message.allowValidation = true" v-on:focus="message.allowValidation = false"></textarea>
        </div>
        <div>
          <input type="submit" value="Send  ➔" @mouseenter="hoverCursor" @mouseleave="normalCursor">
          <p class="confirmationMsg" v-if="submitted">Your message has been sent, we will get back to you soon.</p>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
import {db} from "../firebase"
export default {
  data(){
    return{

      name: {
        value: "",
        allowValidation: false
      },
      email: {
        value: "",
        allowValidation: false
      },
      subject: {
        value: "Purchase enquiry | artwork: "+ this.selectedWork.title,
      },
      message: {
        value: ``,
        maxlength: 2500,
        allowValidation: false
      },
      validForm: false,
      submitted: false

    }
  },
  props: [
    'hoverCursor',
    'normalCursor',
    'selectedWork',
  ],
  computed: {
    validName (){
      return this.name.value ? true : false
    },
    validEmail (){
      return this.isEmail(this.email.value)
    },
    validMessage (){
      return this.message.value.length >= 50 ? true : false
    }
  },
  // Regular expression from W3C HTML5.2 input specification:
// https://www.w3.org/TR/html/sec-forms.html#email-state-typeemail

  methods: {

    // submit form handler
    submit: function() {
      this.validForm = this.validName && this.validEmail && this.validMessage;
      if(this.validForm){
        const messagesRef = db.collection('enquiries')
        messagesRef.add(
          {
            name: this.name.value,
            email: this.email.value,
            subject: this.subject.value,
            message: this.message.value,
            artwork: this.selectedWork.src,
            time: new Date(),
          },
        )
        this.name.value = ''
        this.name.allowValidation = false
        this.email.value = ''
        this.email.allowValidation = false
        this.message.value = ''
        this.message.allowValidation = false
        // this.snackbar = false
        this.submitted = true
      }else{
        console.log("error")
      }
    },
    // check for valid email adress
    isEmail: function(value) {
      var emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRegExp.test(value);
    },
  }

}
</script>

<style lang="scss" scoped>
.vue-form {
  // padding: 15px 30px;
  margin: auto;
  background-color: #fff;
  fieldset{
    padding: 0;
    border: none;
  }
  div {
    position: relative;
    margin: 20px 0;
  }
  .label {
    color: #94aab0;
    display: inline-block;
    line-height: 2rem;
    width: 20%;
  }
  input{
    color: #2b3e51;
    display: block;
    width: 94%;
    appearance: none;
    padding: 10px 3%;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    border-bottom: 1px solid #cfd9db;
    background-color: #ffffff;
  }
  textarea{
    color: #2b3e51;
    display: block;
    width: 94%;
    appearance: none;
    padding: 10px 3%;
    border: 1px solid #cfd9db;
    background-color: #ffffff;
    border-radius: 0.3rem;
    min-height: 120px;
    resize: vertical;
    overflow: auto;
  }
  .error-message {
    display: inline-block;
    margin: 0px;
    padding: 0;
    color: #ff0000;
    font-size: .7rem;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .counter{
    font-size: 0.7rem;
    float: right;
    line-height: 2rem;
    color: #94aab0;
  }
  .confirmationMsg{
    display: inline-block;
    margin: 0px;
    padding: 0;
    font-size: .7rem;
    margin: 0 0 0 1rem;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2b3e51;
  }
}
.vue-form input[type="text"]:focus,
.vue-form input[type="email"]:focus,
.vue-form textarea:focus{
  outline: none;
  border-color: #2c3e50;
}
.vue-form input[type="submit"] {
  font-family: inherit;
  width: initial;
  border: none;
  display: inline-block;
  background: #2c3e50;
  padding: 8px 20px;
  color: #ffffff;
  border-radius: 3rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


</style>