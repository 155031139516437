<template>
	<div class="content">
		<div class="scrollTarget">
			<div class="work" ref="work">
				<div class=" section introText">
					<h1 v-if="!spanish"> Elecktra & Átomo</h1>
					<h1 v-if="spanish"> Elecktra & Átomo</h1>
					<p v-if="!spanish"> In this exhibition the artist shows his main artistic production performed during 2021. Executed pictorially from April to December, the series is about the semi-identical twins Elektra & Atom, and its science-fiction theme reflects a chronicle of the pandemic times we are going through. As the modern Romulus & Remus the mother who feeds them instead of being a wolf is technology and machines. A series dedicated by the artist to his mother, and to all mothers, and to what they represent. <br> 
					In his technique Pacorrosa detaches from the mathematical perfection of the digital aesthetics. Deliberately imperfect, the artist is away from the idealised binary appearance of today’s design reflected by the current norms and tendencies overall in art. <br>
					At the same time with this technique the artist represents a bio-digitalised universe by the nanotechnology, which means that this work is mostly closer to something natural opposed to the perfectionist, digitalised and vectored way which humans have accepted as the norm. To explain it in a simpler way, it is like the relationship between the circle and the ring. <br> 
					These paintings tell the story of the 21st century twins, a morphologically developed beings through the inoculation of nanotechnology during their gestation. This provides them with telepathic and empathic abilities. With the time they develop these qualities up to a level in which they can communicate with the past, which is our present. They pretend to change certain past events, which have determined their development. <br>
					Deprived from having any contact with their biological mother from the 27th of September 2021, they start asking for help through the creations of the artist Pacorrosa among others, as the telepathic frequency they use is the estate of consciousness activated during the creative process, in connection with the collective consciousness. <br>
					This work speaks about the essential role of the mother and her importance in the development of the social personality of human beings.<br>
					Conceptually it delves into the idea of the self-fulfilled prophecy, where the organisation in charge of transforming the evolution of these special twins, using the latest technology with nanorobots, based in the conspirative believe that multinationals and the people who rule the world can insert nano-robots in the world population, in the covid-19 vaccine, in order to control them and to provoke the Great Reboot in the history of our civilisation. Through this international act, each human being can be manipulated, both psychologically and morphologically, controlling their actions and health.<br>
					Elektra & Atom are the super heroes in charge of preventing and abolishing this conspiracy, forcing the nanorobots to leave the bodies of the people they have occupied like parasites, using a super human telepathic connection. <br>
					<span v-if="!isMobile"> scroll up/down to move right/left</span></p>
					
					
					<p v-if="spanish">En esta exposición el artista muestra su producción artística central realizada durante el año 2021. Ejecutada pictóricamente desde abril a diciembre, la serie versa sobre los gemelos 	semi-idénticos ElecKtra y Átomo.<br>
					En su temática de ciencia ficción la serie esconde una crónica de la etapa pandémica que estamos viviendo. ElecKtra & Átomo son como los contemporáneos Rómulo y Remo donde la madre que los amamanta en vez de ser una loba es la tecnología, las máquinas. Una serie que el artista dedica a su madre, a todas las madres, a lo que representan individual y culturalmente.<br>
					Formalmente Pacorrosa indaga en una técnica pictórica centrada en apartarse de la perfección matemática de la estética digital. Con una factura deliberadamente imperfecta el artista se aleja de la idealizada apariencia binaria del diseño de nuestro tiempo, que marca los cánones y tendencias actuales en la mayor parte del arte.<br>
					Al mismo tiempo con esta técnica, nuestro artista, representa un universo bio-digitalizado por la nanotecnología, esto quiere decir que estos trabajos pretenden acercarse lo más posible a algo natural en contrapartida a el hacer perfeccionado, vectorizado, digitalizado que hemos normalizado los humanos. Explicándolo de forma más sencilla es como la relación entre el redondel y el circulo.<br>
					Estas pinturas narran la historia de los gemelos del siglo XXI, unos seres evolucionados morfológicamente a través inoculación de nanotecnología durante su gestación. Esto les proporciona una capacidad empático-telepática, con los años desarrollan esta cualidad hasta el nivel de que son capaces comunicarse con el pasado, nuestro presente, usando sus cuerpos como antenas. Pretenden cambiar eventos de su pasado que han determinado su crecimiento y el desarrollo social del proyecto militar al que pertenecen.<br>
					Privados de el contacto con su madre biológica desde el 27 de Septiembre del año 2021, comienzan pidiéndonos ayuda a través de las creaciones del artista Pacorrosa entre otros para encontrar a su madre. Esto es posible ya que la frecuencia telepática que utilizan es el estado de conciencia activado durante el proceso creativo en conexión con el inconsciente colectivo. Frecuencia muy cercana a la que produce la meditación.<br>
					Estas obras nos hablan de la importancia del la madre como pilar esencial en la formación de la personalidad social de los seres humanos.<br>
					Conceptualmente indaga en la idea de la profecía auto-cumplida, donde la organización encargada de transformar evolutivamente a estos gemelos sesquizigóticos con tecnología punta formada por nanorobots, basados en la creencia conspiranóide de que las multinacionales y cúpula de los individuos que controlan el mundo, de insertar nanorobots en la población mundial con la vacuna del Covid-19, para controlarlos y provocar el Gran Reseteo de nuestra civilización. Con este acto internacional, cada humano puede ser manipulado tanto psicológica como morfológicamente, teniendo poder sobre sus acciones y salud.<br><br>
					ElecKtra y Átomo son los superhéroes encargados de dificultar y abolir esta supuesta conspiración forzando a los nanobots a abandonar los cuerpos de las personas a las que parasitan, usando una conexión telepática sobrehumana.</p>


					<p class="arrow">
						<span>
							<svg  ref="arrow" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 50" width="60px" height="25px" style="enable-background:new 0 0 100 50;" xml:space="preserve">
								<g>
									<path d="M93.9,27.2H6.1c-1.2,0-2.2-1-2.2-2.2s1-2.2,2.2-2.2h87.7c1.2,0,2.2,1,2.2,2.2S95.1,27.2,93.9,27.2z"/>
									<path d="M93.9,27.2c-0.4,0-0.9-0.1-1.3-0.4L61.4,4.9c-1-0.7-1.2-2.1-0.5-3.1c0.7-1,2.1-1.2,3.1-0.5l31.2,21.9c1,0.7,1.2,2.1,0.5,3.1C95.2,26.9,94.5,27.2,93.9,27.2z"/>
									<path d="M62.7,49.1c-0.7,0-1.4-0.3-1.8-0.9c-0.7-1-0.5-2.4,0.5-3.1l31.2-21.9c1-0.7,2.4-0.5,3.1,0.5c0.7,1,0.5,2.4-0.5,3.1L63.9,48.7C63.5,49,63.1,49.1,62.7,49.1z"/>
								</g>
							</svg>
						</span>
					</p>
				</div>
				<!-- <iframe src="https://www.vrte.es/cajarural/pacorrosa/index.htm" title="exhibition tour"></iframe> -->
				<img v-for="painting in $root.allWorks.RoomEA.paintings" v-bind:key="painting.id" v-bind:src="painting.src" v-bind:alt="painting.title">
			</div>
			<div class="background"></div>
		</div>
	</div>
</template>

<script>
import {gsap} from 'gsap';
import {db} from '../firebase'
export default {
	data() {
		return{
			id: this.$root.allWorks.RoomEA.id,
			showDetails: false,
			Room1Paintings: []
		}
	},
	props:[
        'spanish',
				'isMobile'
	],
	
	methods: {
		scrollRight(){
			const arrow = this.$refs.arrow;
			var tl = gsap.timeline();
			tl.set(arrow, {x: -100})
			tl.to(arrow, {x: 100, duration: 1.5, repeat:3})
			tl.fromTo(arrow, {x:-100}, {x:0, duration: 1.5})
		},
		getPaintings(){
      db.collection("Room1").orderBy('id').get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          var painting = {id: doc.id, data: doc.data()}
          this.Room1Paintings.push(painting)
        });
				this.$emit('roomLoaded')
      });
    }
	},
	created(){
		this.getPaintings()
		// console.log(this.Room1Paintings)
	},
	mounted(){
		this.scrollRight();
	},

}
</script>

<style lang="scss" scoped>


  @mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }

	iframe{
		width: 80vw;
		height: 100%;
		margin: 0 10vw 0 10vw;
		border: none;
	}


.background{
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    z-index: -1;
    background-size: 80px 80px;
    background-image: radial-gradient(circle, #d2d2d2 1px, #ffffff 4px);
    // background-image: linear-gradient(to right, #cccccc 2px, transparent 1px), linear-gradient(to bottom, #cccccc 1px, transparent 1px);
}



</style>