<template>
  <div class="wrapper">
    <div class="Module" ref="editing" v-if="Module" @click="closeModule">
      <form class="Form" @submit.prevent="">
          <div>
            <h5 class="formTitle" v-if="editing">Edit painting</h5>
            <h5 class="formTitle" v-if="!editing">Add painting</h5>
          </div>
      <fieldset>
        <div class="column">
          <div>
            <label class="label" for="name">painting name English</label>
            <input type="text" name="title" id="title" required v-model="activeItem.data.title">
          </div>
          <div>
            <label class="label" for="name">painting name Spanish</label>
            <input type="text" name="titleES" id="titleES" required v-model="activeItem.data.titleES">
          </div>
          <div>
            <label class="label" for="name">painting id</label>
            <input type="text" name="title" id="id" v-if="editing" required v-model="activeItem.data.id">
            <input type="text" name="title" id="id" v-if="!editing" required v-model="activeItem.data.id" readonly>
          </div>
          <div>
            <label class="label" for="price">price</label>
            <input type="text" name="price" id="price" required v-model="activeItem.data.price">
          </div>
          <div>
            <label class="label" for="price">Available for sale?</label>
            <input type="checkbox" name="available" id="available" required v-model="activeItem.data.available" :true-value="true" :false-value="false">
          </div>
          <div>
            <label class="label" for="price">Summary English</label>
            <input type="text" name="price" id="summary" required v-model="activeItem.data.summary">
          </div>
          <div>
            <label class="label" for="price">Summary Spanish</label>
            <input type="text" name="price" id="summaryES" required v-model="activeItem.data.summaryES">
          </div>
        </div>


        <div class="column">
          <div>
            <label class="label" for="textarea">description English</label>
            <textarea class="description" name="description" v-model="activeItem.data.description"></textarea>
          </div>
          <div>
            <label class="label" for="textarea">description Spanish</label>
            <textarea class="description" name="descriptionES" v-model="activeItem.data.descriptionES"></textarea>
          </div>
          <div>
            <input type="file" class="mainImage" name="mainImage" required v-if="!editing" @change="fileExists">
            <img width="100px" v-if="activeItem.data.src" :src="filePreview" :title="activeItem.data.src.name">
          </div>
          <div style="text-align: right;">
            <button type="button" class="button" @click="updateItem" v-if="editing">Submit changes</button>
            <button type="button" class="button" @click="addPainting" v-if="!editing">Add Painting</button>
            <button type="button" class="cancel" ref="cancel" @click="closeModule">cancel</button>
          </div>
        </div>
      </fieldset>
    </form>
    </div>
    <h3>All paintings</h3>
    <p>This is the admin page for all the paintings on the website. Here you can add new paintings to the 'Basement' of the website. (in all artworks that are not listed in the gallery/rooms) You can delete Paintings or edit painting info. <br><br> <strong>Please Note</strong> for now, this is not a real time database, meaning every change requires a reload of the page. <br><br> before adding new paintings please click on the edit button of one of the paintings below to see how the data is logged then please follow the same convention/method to enter the data for the new painting</p>
    <button id="addPainting" @click="loadAddingModule"> add Painting </button>
    <table>
      <tr v-for="painting in paintings" v-bind:key="painting.id">
        <td>
          <img v-bind:src="painting.data().src" v-bind:alt="painting.data().title">
        </td>
        <td>{{painting.data().title}}</td>
        <td>£ {{painting.data().price}}</td>
        <td>
          <button class="delete" @click="deletePainting(painting)">delete</button>
        </td>
        <td>
          <button class="edit" @click="loadEditModal(painting)">edit</button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import {fb, db} from '../firebase'
export default {
  data(){
    return{
      paintings: [],
      basementCount: null,
      activeItem: {
        id: null,
        data: {
          title: null,
          titleES: null,
          id: null,
          price: null,
          src: null,
          available: true,
          summary: null,
          summaryES: null,
          description: null,
          descriptionES: null
        }
      },
      filePreview: null,
      activeItemCollection: null,
      Module: false,
      editing: false,
    }
  },
  mounted(){ 
    this.getPaintings()
  },
  computed:{
    validForm(){
    if(this.activeItem.data.title && this.activeItem.data.titleES && this.activeItem.data.id && this.activeItem.data.price && this.activeItem.data.src && this.activeItem.data.summary && this.activeItem.data.summaryES){
      return true;
    }else{
      return false;
    }
    },
  },
  methods: {
    getPaintings(){
      db.collection("Room1").orderBy('id').get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          var painting = doc;
          this.paintings.push(painting);
        });
      });
      db.collection("Room2").orderBy('id').get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          var painting = doc;
          this.paintings.push(painting);
        });
      });
      db.collection("Basement").orderBy('id').get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          var painting = doc;
          this.paintings.push(painting);
          this.basementCount = querySnapshot.size;
        });
      });
    },
    loadEditModal(painting){
      this.Module = true;
      this.editing = true;
      this.activeItem.id = painting.id;
      this.activeItem.data = painting.data();
      this.activeItemCollection = painting.ref.parent.path;
    },
    loadAddingModule(){
      this.Module = true;
      this.activeItem.data.id = "B" + (this.basementCount + 1)
    },
    closeModule(e){
			if (e.target === this.$refs.editing || e.target === this.$refs.cancel){
				this.Module = false;
        this.dataReset();
			}
		},
    deletePainting(painting){
      if(confirm("are you sure you want to delete this painting?")){
        let imgFile = fb.storage().refFromURL(painting.data().src)
        imgFile.delete().then(() => {
          console.log('image deleted')
          db.collection(painting.ref.parent.path).doc(painting.id).delete().then(() => {
              alert("Document successfully deleted!");
          }).catch((error) => {
              console.error("Error removing document: ", error);
          });
        }).catch((error) => {
          console.log("can't delete file because: ", error)
        })
      }
    },
    updateItem(){
      db.collection(this.activeItemCollection).doc(this.activeItem.id).update(this.activeItem.data)
      .then(() => {
          this.Module = false;
          alert("Document successfully updated! you need to refresh the page to see the changes");
          this.dataReset();
      })
      .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
          alert("Error updating document: ", error);
      });
    },
    fileExists(e){
      let file = e.target.files[0];
      this.filePreview = URL.createObjectURL(file)
      this.activeItem.data.src = file;
    },
    addPainting(){

      if(this.validForm){

        let file = this.activeItem.data.src;
        let storageRef = fb.storage().ref("Basement/" + file.name);
        let uploadTask = storageRef.put(file);

        uploadTask.on('state_change', (snapshot)=> {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          }, (error) => {
            alert("There was an error while uploading the file" + error)
          }, () => {

            uploadTask.snapshot.ref.getDownloadURL().then((DownloadURL) => {
              console.log(DownloadURL)
              this.activeItem.data.src = DownloadURL;
            }).then(() => {
              db.collection("Basement").add(this.activeItem.data).then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
                this.Module = false;
              })
              .catch((error) => {
                console.error("Error adding document: ", error);
              });
            });
          }
        )
        
      }else{
        alert("you must fill all required fields")
      }
    },
    dataReset(){
      this.activeItem.data.title = null;
      this.activeItem.data.titleES = null;
      this.activeItem.data.id = null;
      this.activeItem.data.price = null;
      this.activeItem.data.src = null;
      this.activeItem.data.available = true;
      this.activeItem.data.summary = null;
      this.activeItem.data.summaryES = null;
      this.activeItem.data.description = null;
      this.activeItem.data.descriptionES = null;
      this.editing = false
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper{
  padding: 0 20px 0 20px;
  table{
    width: 100%;
    tr{
      display: flex;
      height: 120px;
      align-items: center;
      padding: 10px 0 10px 0 ;
      margin: 5px 0 0 0;
      border: solid 1px;
      td{
        width: 30%;
      }
      td:nth-child(1){
        width: 15%;
      }
      img{
        max-width: 100px;
        max-height: 100%;
        height: auto;
        align-self: center;
        margin: 0 10px 0 10px;
      }
    }
    .delete{
      display: block;
      color: white;
      background-color: red;
      border: none;
      padding: 5px 10px;
      border-radius: 20px;
    }
    .edit{
      display: block;
      color: white;
      background-color: #2c3e50;
      border: none;
      padding: 5px 10px;
      border-radius: 20px;
    }
  }
  #addPainting{
    display: block;
    color: white;
    background-color: #2c3e50;
    border: none;
    margin: 20px 0;
    padding: 10px 20px;
    border-radius: 30px;
  }
  .formTitle{
    text-align: center;
    font-weight: 600;
  }
  .Module{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: 30;
    overflow-y: scroll;
      //firefox
    scrollbar-width: none;
    .Form{
      width: 80%;
      padding: 30px;
      margin: 100px auto;
      border-radius: 10px;
      background: white;
      box-shadow: 0 0 8px rgba(0,0,0,0.1);
      fieldset{
        display: flex;
      }
      .column{
        width: 50%;
      }
      .label {
        color: #94aab0;
        display: inline-block;
        line-height: 2rem;
        width: 100%;
      }
      input{
        color: #2b3e51;
        display: block;
        width: 94%;
        // appearance: none;
        padding: 10px 2%;
        border-top: none;
        border-right: none;
        border-left: none;
        border-radius: 0;
        border-bottom: 1px solid #cfd9db;
        background-color: #ffffff;
      }
      input[type="checkbox"]{
        width: initial;
        height: 30px;
      }
      textarea{
        color: #2b3e51;
        display: block;
        width: 94%;
        appearance: none;
        padding: 10px 2%;
        border: 1px solid #cfd9db;
        background-color: #ffffff;
        border-radius: 0.3rem;
        min-height: 150px;
        resize: vertical;
        overflow: auto;
      }
      button{
        color: white;
        margin: 20px 20px;
        padding: 10px 20px;
        border: none;
        border-radius: 30px;
        background-color: #2b3e51;
      }
      button.cancel{
        background-color: #7b7b7b;
      }
    }
  }
}
</style>