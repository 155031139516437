<template>
	<div class="content">
		<div class="scrollTarget">
			<div class="work" ref="work">
				<div class=" section introText">
					<h1 v-if="!spanish">Sketch for a Satellite</h1>
					<h1 v-if="spanish">Boceto para Satellite</h1>
					<p>Sketch for a Satellite is by and large the biggest piece of art Pacorrosa has conceived, not only due to its dimensions, but due to the transcendent nature of its design. He has built bridges between science, technology and art, using nature as a canvas where live materials take their own course. This is an essential concept of Bioart.<br><br>
					&lsquo;Sketch for a Satellite&rsquo; is a macrodrawing made on earth. As its name suggests, the best way to view the piece was via satellite. With an area of 74,400 square meters this piece was made using the technique of ‘land art’ with wheat and plastic as the main materials.
					</p>
					<p class="arrow">
						<span>
							<svg  ref="arrow" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 50" width="75px" height="30px" style="enable-background:new 0 0 100 50;" xml:space="preserve">
								<g>
									<path d="M93.9,27.2H6.1c-1.2,0-2.2-1-2.2-2.2s1-2.2,2.2-2.2h87.7c1.2,0,2.2,1,2.2,2.2S95.1,27.2,93.9,27.2z"/>
									<path d="M93.9,27.2c-0.4,0-0.9-0.1-1.3-0.4L61.4,4.9c-1-0.7-1.2-2.1-0.5-3.1c0.7-1,2.1-1.2,3.1-0.5l31.2,21.9c1,0.7,1.2,2.1,0.5,3.1C95.2,26.9,94.5,27.2,93.9,27.2z"/>
									<path d="M62.7,49.1c-0.7,0-1.4-0.3-1.8-0.9c-0.7-1-0.5-2.4,0.5-3.1l31.2-21.9c1-0.7,2.4-0.5,3.1,0.5c0.7,1,0.5,2.4-0.5,3.1L63.9,48.7C63.5,49,63.1,49.1,62.7,49.1z"/>
								</g>
							</svg>
						</span>
					</p>
				</div>
				<img v-for="artwork in imgSet1" v-bind:key="artwork.id" :src="artwork.src" :alt="artwork.title">

				<div class="copyWrapper">
					<p class="copy">Flying over the artwork was the local way to enjoy it. It included routes for paragliding, air balloons and ultra-light planes.<br><br>
						Agricultural machines such as a tractor were used as tools to draw. For further information about the process of creation and the concept of this artwork, you can watch the documentary video here.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &#10132; <br><br>
						This artworks occurred between 2008 to 2011 during three natural cycles: one to develop the art work with wheat; the second to transform the art as nature wanted; and the third to eliminate the impact of nature, finishing this ephemeral piece of art.
					</p>
				</div>

				<img v-for="artwork in imgSet2" v-bind:key="artwork.id" :src="artwork.src" :alt="artwork.title">
				<video class="video" controls poster=".././assets/imgs/room3/video-poster.jpg" @mouseenter="hoverCursor" @mouseleave="normalCursor">
					<source src=".././assets/imgs/room3/LIV_MCD-SketchForASatellite.mp4" type="video/mp4">
				</video>
				<img v-for="artwork in imgSet3" v-bind:key="artwork.id" :src="artwork.src" :alt="artwork.title">

				<div class="copyWrapper">
					<p class="copy">In an historical period as ours, when visions are changing, we are able to perceive the limits of the current social and ethical sense. Awareness is being raised of the need to watch over an appropriate and sustainable living space. Ideally, it would provide decent conditions for human beings, making it possible to preserve Mother Earth and its biodiversity.<br><br>
					&ldquo;LIV_MCD Sketch for a Satellite&rdquo; is a fully contemporary piece of work. It provokes reflection in its conceptual dimension and in its connection with the earth.<br><br>
					It is not fashionable. It is carried out altruistically, trying to transmit a message for a new era, which needs to be based in sustainable ecology. The artist works outdoors, replacing the farmer to become a draftsman, creating an aptly ephemeral work of art, where the geometrical and the organic interact in natural growing biological cycles.
					</p>
				</div>

				<img v-for="artwork in imgSet4" v-bind:key="artwork.id" :src="artwork.src" :alt="artwork.title">

				<div class="copyWrapper">
					<p class="copy">We are unable to transmit the real individual experience of artistic contemplation, authentic and irreplaceable as a generating device of ideas and emotions. However we will try to describe its symbolic elements as a gateway to it:<br><br>
					Spiral forms are natural growing patterns that permeate nature and the figure of the human foetus does not need explaining for its clarity. Both convey the concept of natural and human nature as eminently creative ones.<br><br>
					The heroic technique chosen by the artist is based on natural and real growth to create the work. He has sown wheat in the supporting space, to later draw these symbols so that the spectator gains some kind of emotion or experience.<br><br>
					&ldquo;LIV_MCD Sketch for a Satellite&rdquo;, as one could infer from its name, is an action leading to aerial observation, using the actual land as a canvas. This megadrawing takes up a total area of 74.400 m2.
					</p>
				</div>

				<img v-for="artwork in imgSet5" v-bind:key="artwork.id" :src="artwork.src" :alt="artwork.title">

				<div class="copyWrapper">
					<p class="copy">It is not random that the star spectator chosen by the artist is a satellite. Its main aim is to observe and to provide images and information. It helps and controls, whether as an environmental tool or as a spy above our heads.In this particular case, its role is that of a receiver/spectator. In the author’s own words, this work represents the human essence, both natural and abstract; it is an “offering to the satellite, to be received by any intelligent being, in or out of our own planet”. A real statement of intent.<br><br> Pacorrosa takes a stand about the need for awareness about living wisely. One can easily perceive this in his piece of “Bio-Art”, which is filled with nuances and suggests a somehow outside-inward vision. He is able to create a piece of work that communicates in situ a timeless poetic mystery, trace of a sensitive and creative intelligence, away from justification, as a true example of Free Art.
					</p>
				</div>

				<img v-for="artwork in imgSet6" v-bind:key="artwork.id" :src="artwork.src" :alt="artwork.title">
			</div>
			<div>
			</div>
			<div class="background"></div>
		</div>
	</div>
</template>

<script>
import {gsap} from 'gsap';
export default {
	data() {
		return{
			id: this.$root.allWorks.Room3.id,
			showDetails: false,
			imgSet1: this.$root.allWorks.Room3.paintings.slice(0, 2),
			imgSet2: this.$root.allWorks.Room3.paintings.slice(2, 4),
			imgSet3: this.$root.allWorks.Room3.paintings.slice(4, 7),
			imgSet4: this.$root.allWorks.Room3.paintings.slice(7, 10),
			imgSet5: this.$root.allWorks.Room3.paintings.slice(10, 15),
			imgSet6: this.$root.allWorks.Room3.paintings.slice(15),
			
		}
	},
	props:[
        'spanish',
				'hoverCursor',
				'normalCursor'
	],

	mounted(){
		this.scrollRight();
	},
	
	methods: {
		scrollRight(){
			const arrow = this.$refs.arrow;
			var tl = gsap.timeline();
			tl.set(arrow, {x: -100})
			tl.to(arrow, {x: 100, duration: 1.5, repeat:3})
			tl.fromTo(arrow, {x:-100}, {x:0, duration: 1.5})
		}
	}
}
</script>

<style lang="scss" scoped>


  @mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }

video::-webkit-media-controls-play-button,
video::-webkit-media-controls-mute-button,
video::-webkit-media-controls-timeline, 
video::-webkit-media-controls-volume-slider,
video::-webkit-media-controls-fullscreen-button {
	cursor: none;
}
.videocontrols {
	display: none;
}
video{
	@include for-phone-only(){
		width: 100vw;
	}
	width: 1280px;
	flex-shrink: 0;
}

.copyWrapper{
	@include for-phone-only(){
		min-width: 90vw;
		max-width: 100vw;
		height: 95%;
	}
	min-width: 40vw;
	max-width: 70vw;
	overflow-y: scroll;
	padding: 10vh 0;
	.copy{
		@include for-phone-only(){
			font-size: 0.8rem;
			margin: 3rem 0 0 0;
		}
	}
}

.background{
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    z-index: -1;
    background-size: 80px 80px;
    background-image: radial-gradient(circle, #d5d5d5 1px, #ffffff 4px);
    // background-image: linear-gradient(to right, #cccccc 2p	x, transparent 1px), linear-gradient(to bottom, #cccccc 1px, transparent 1px);
}



</style>