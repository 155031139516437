import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import Lobby from '../views/Lobby'
import RoomEA from '../views/RoomEA'
import Room1 from '../views/Room1'
import PainterlyExpressions from '../views/Painterly-expressions'
import SketchForASatellite from '../views/Sketch-for-a-Satellite'
import AllWorks from '../views/AllWorks'
import info from '../views/info.vue'
import cart from '../views/cart.vue'
import Admin from '../views/Admin.vue'
import login from '../views/login.vue'
import {fb} from '../firebase'
import adminPaintings from '../views/adminPaintings.vue'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    name: 'Lobby',
    component: Lobby,
  },
  {
    path: '/ea',
    name: 'RoomEA',
    component: RoomEA,
    props: {id: 0}
  },
  {
    path: '/Room1',
    name: 'Room1',
    component: Room1,
    props: {id: 1}
  },
  {
    path: '/Painterly-expressions',
    name: 'Painterly-expressions',
    component: PainterlyExpressions,
    props: {id: 2}
  },
  {
    path: '/Sketch-for-a-Satellite',
    name: 'Sketch-for-a-Satellite',
    component: SketchForASatellite,
    props: {id: 3}
  },  
  {
    path: '/AllWorks',
    name: 'AllWorks',
    component: AllWorks,
    props: {id: 4}
  },
  {
    path: '/info',
    name: 'info',
    component: info,
    props: {id: 5}
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    props: {id: 100}
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    props: {id: 1000},
    meta: {requiresAuth: true },
    children:[
      {
        path: 'adminPaintings',
        name: 'adminPaintings',
        component: adminPaintings,
      },
    ]
  },
  {
    path: '/cart',
    name: 'cart',
    component: cart,
    props: {id: 200}
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

  /*eslint-disable*/
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 5)
      if ('scrollRestoration' in history) 
      { 
        history.scrollRestoration = 'manual'; 
      }
    })
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (fb.auth().currentUser) {
      next();
    } else {
      next({
        path: '/login',
      });
    }
  } else {
    next();
  }
});

export default router
