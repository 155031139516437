<template>
  <div class="login" ref="login">
      <div class="modal">
        <form class="login-form" @submit.prevent="login">
          <fieldset>
            <div>
              <label class="label" for="email" >User name</label>
              <input type="email" name="email" id="email" v-model="email" required>
            </div>
            <div>
              <label class="label" for="textarea">Password</label>
              <input type="password" name="password" id="password" v-model="password" required @keyup.enter="login">
            </div>
            <div>
              <button>Log in</button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
</template>

<script>
import {fb} from '../firebase'
export default {
  data(){
    return{
      email: null,
      password: null
    }
  },

  methods:{
    login() {
      fb
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          this.$router.push('/admin/adminPaintings');
        })
        .catch(error => {
          alert(error.message);
        });
    },
  }
}
</script>

<style lang="scss" scoped>

.login-form {
  width: 450px;
  padding: 15px 30px;
  margin: 50px auto;
  background-color: #fff;
  fieldset{
    border: none;
  }
  div {
    position: relative;
    margin: 20px 0;
  }
  .label {
    color: #94aab0;
    margin-bottom: 10px;
    display: inline-block;
    line-height: 2rem;
    width: 20%;
  }
  input{
    color: #2b3e51;
    display: block;
    width: 94%;
    appearance: none;
    padding: 10px 3%;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #cfd9db;
    background-color: #ffffff;
  }
}
.vue-form input[type="text"]:focus,
.vue-form input[type="email"]:focus,
.vue-form textarea:focus{
  outline: none;
  border-color: #2c3e50;
  box-shadow: 0 0 5px rgba(44, 151, 222, 0.2);
}
button {
  display: block;
  font-family: inherit;
  margin: 50px auto;
  border: none;
  background: #2c3e50;
  border-radius: 3rem;
  padding: 10px 20px;
  color: #ffffff;
}

</style>