<template>
  <div class="navSection">
    <div class="prev" >
      <router-link :to="previous" @mouseenter.native="hoverCursor" @mouseleave.native="normalCursor">
        <span v-if="!spanish && !isMobile">previous Room</span>
        <span v-if="spanish && !isMobile">sala anterior</span>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
          <g>
            <path d="M26.3,4.1V46c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3V4.1c0-0.7,0.6-1.3,1.3-1.3C25.7,2.8,26.3,3.3,26.3,4.1z"/>
            <path d="M26.3,4.1c0,0.2-0.1,0.5-0.2,0.8L12.9,23.6c-0.4,0.6-1.3,0.7-1.9,0.3c-0.6-0.4-0.7-1.3-0.3-1.9L23.9,3.3c0.4-0.6,1.3-0.7,1.9-0.3C26.1,3.3,26.3,3.7,26.3,4.1z"/>
            <path d="M39.5,22.8c0,0.4-0.2,0.8-0.5,1.1c-0.6,0.4-1.4,0.3-1.9-0.3L23.9,4.8c-0.4-0.6-0.3-1.4,0.3-1.9c0.6-0.4,1.4-0.3,1.9,0.3l13.1,18.8C39.4,22.3,39.5,22.5,39.5,22.8z"/>
          </g>
        </svg>

      </router-link>
    </div>
    <div class="next" >
        <div class="nextProject">
          <router-link :to="next" @mouseenter.native="hoverCursor" @mouseleave.native="normalCursor">
            <span v-if="!spanish && !isMobile">Next Room</span>
            <span v-if="spanish && !isMobile">Siguiente Sala</span>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <g>
                <path d="M23.7,45.9V4c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3v41.8c0,0.7-0.6,1.3-1.3,1.3C24.3,47.2,23.7,46.7,23.7,45.9z"/>
                <path d="M23.7,45.9c0-0.2,0.1-0.5,0.2-0.8l13.1-18.7c0.4-0.6,1.3-0.7,1.9-0.3s0.7,1.3,0.3,1.9L26.1,46.7c-0.4,0.6-1.3,0.7-1.9,0.3C23.9,46.7,23.7,46.3,23.7,45.9z"/>
                <path d="M10.5,27.2c0-0.4,0.2-0.8,0.5-1.1c0.6-0.4,1.4-0.3,1.9,0.3l13.1,18.7c0.4,0.6,0.3,1.4-0.3,1.9c-0.6,0.4-1.4,0.3-1.9-0.3L10.8,27.9C10.6,27.7,10.5,27.5,10.5,27.2z"/>
              </g>
            </svg>

          </router-link>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      gallery: ['/ea', '/Room1', '/Painterly-expressions', '/Sketch-for-a-Satellite']
    }
  },
  props:[
    'hoverCursor',
    'normalCursor',
    'spanish',
    'isMobile'
	],
  computed: {
    previous() {
      var prevIndex = this.gallery.indexOf(this.$route.path)-1
      if (prevIndex >= 0){
      var prev = this.gallery[prevIndex]
      }else{
          prev = ""
      }
      return prev
    },
    next() {
      var nextIndex = this.gallery.indexOf(this.$route.path)+1
      if (nextIndex < this.gallery.length){
      var next = this.gallery[nextIndex]
      }else{
          next = ""
      }
      return next
    }
  },
}
</script>

<style lang='scss' scoped>

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portait-only {
  @media (min-width: 600px) and (max-width: 899px) { @content; }
}

.navSection{
  @include for-phone-only {
    padding: 0;
    width: 30%;
    font-size: 0.7rem;
  }
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 50vw;
  padding: 0 0 1rem 3rem;
  div{
    padding: 0 1rem 0 1rem;
    @include for-phone-only {
      padding: 0 0 0 1rem;
    }
  }
  svg{
    width: 30px;
  }
}
.prev{
  padding: 0;
  a{
    display: block;
    display: flex;
    align-items: center;
  }
  span{
    width:100%;
  }
}
.next{
  text-align: center;
  a{
    display: block;
    display: flex;
    align-items: center;
  }
  span{
    width:100%;
    white-space: nowrap;
  }
}

</style>