<template>
    <div id="cart">
        <ul id="cartPaintings">
            <li class="painting" ref="paintings" v-bind:key="painting.id" v-for="painting in $root.cart"> 
                <img class=" section" v-bind:id="painting.title" v-bind:src="painting.src" v-bind:alt="painting.title">
                <p class="title">{{painting.summary}}</p>
                <p class="workPrice">price: {{painting.price}}</p>
            </li>
        </ul>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    #cart{
        ul{
            height: 20vh;
            margin: 0;
            li{
                // height: 20vh;
                img{
                    // height: 100%;
                    max-width: 20vw;
                }
            }
        }
    }

</style>